/* -----------------------------------------
   Duallistbox
----------------------------------------- */
.bootstrap-duallistbox-container {
    
    .buttons {
        width: 100%;
        margin-bottom: -1px;
    }
    .box1 {
        .filtered {
            .clear1 {display: inline-block;}
        }
    }
    .box2 {
        .filtered {
            .clear2 {display: inline-block;}
        }
    }
    label {display: block;}
    .info {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 11px;
    }
    .clear1, .clear2 {
        display: none;
        font-size: 10px;
    }
    .move, .remove {width: 60%;}
    .btn-group {
        .btn {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            i {
                color: #acb1b8;
            }
        }
    }
    select {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;
        height: 300px;
        padding: 0;
        border-color: @border-color;
    }
    .moveall, .removeall {width: 40%;}
    .filter {
        width: 100%;
        height: 34px;
        margin: 0 0 5px 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: @padding-base-vertical @padding-base-horizontal;
        font-size: @font-size-base;
        line-height: @line-height-base;
        color: @input-color;
        background-color: @input-bg;
        background-image: none;
        border: 1px solid @input-border;
        border-radius: @input-border-radius;
        .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
        .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

        .placeholder {color: @input-color-placeholder;}
    }
    .moveonselect {
        .move, .remove {
            display:none;
        }
        .moveall, .removeall {
            width: 100%;
        }
    }
}