// Backgrounds mixin
// -------------------------
.panel-bg(@bg) {
    background: @bg;
    background-color: @bg;
}

// Custom mixins for hardware acceleration for webkit
// --------------------------------------------------
.webkitForceHardwareAcceleration() {
-webkit-transform: translate3d(0, 0, 0);
-webkit-backface-visibility: hidden;
-webkit-perspective: 1000;
}

// Retina ready mixins for images
// --------------------------------------------------
// A helper mixin for applying high-resolution background images (http://www.retinajs.com)
// Usage  .at2x('/images/my_image.png', 200px, 100px);

@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

.at2x(@path, @w: auto, @h: auto) {
  background-image: url(@path);
  @at2x_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;

  @media @highdpi {
    background-image: url("@{at2x_path}");
    background-size: @w @h;
  }
}