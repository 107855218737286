/* -----------------------------------------
   Pace loader
----------------------------------------- */
.pace .pace-progress {
  background: @white;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 2px;

  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.pace-inactive {
  display: none;
}