/* -----------------------------------------
   Onof switch
----------------------------------------- */
.onoffswitch {
    position: relative; width: 85px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    display: inline-block;

    &.onoffswitch-disabled {
        .opacity(0.5);
    }
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #FFFFFF; border-radius: 50px;
    margin-bottom: 0;
}
.onoffswitch-inner {
    width: 200%; margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s; -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s; transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    float: left; width: 50%; height: 28px; padding: 0; line-height: 28px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 18px;
    background-color: @gray-spr; 
    color: @green;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 18px;
    background-color: @gray-spr; 
    color: @red;
    text-align: right;
}
.onoffswitch-switch {
    width: 30px; 
    height: 30px;
    margin: 0.5px;
    background: @red;
    border: 2px solid #FFFFFF; 
    border-radius: 50px;
    position: absolute; top: 0; bottom: 0; right: 53px;
    -moz-transition: all 0.3s ease-in 0s; -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s; transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
    background-color: @green; 
}