/* -----------------------------------------
   Bootstrap sliders
----------------------------------------- */
@slider-line-height: @line-height-computed;

.slider {
	margin-top: 2px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	&.slider-horizontal {
		width: 100%;
		height: @slider-line-height;
		.slider-track {
			height: @slider-line-height/2;
			width: 100%;
			top: 50%;
			left: 0;
		}
		.slider-selection {
			height: 100%;
			top: 0;
			bottom: 0;
		}
		.slider-handle {
			margin-left: -@slider-line-height/2;
			margin-top: -@slider-line-height/4;
			&.triangle {
    			border-width: 0 @slider-line-height/2 @slider-line-height/2 @slider-line-height/2;
				width: 0;
				height: 0;
				border-bottom-color: #0480be;
				margin-top: 0;
			}
		}
	}
	&.slider-vertical {
		height: 210px;
		width: @slider-line-height;
		.slider-track {
			width: @slider-line-height/2;
			height: 100%;
			margin-left: -@slider-line-height/4;
			left: 50%;
			top: 0;
		}
		.slider-selection {
			width: 100%;
			left: 0;
			top: 0;
			bottom: 0;
		}
		.slider-handle {
			margin-left: -@slider-line-height/4;
			margin-top: -@slider-line-height/2;
			&.triangle {
				border-width: @slider-line-height/2 0 @slider-line-height/2 @slider-line-height/2;
				width: 1px;
				height: 1px;
				border-left-color: #0480be;
				margin-left: 0;
			}
		}
	}
	&.slider-disabled {
		.slider-handle {
			#gradient > .vertical(#dfdfdf, #bebebe);
		}
		.slider-track {
			#gradient > .vertical(#e5e5e5, #e9e9e9);
			cursor: not-allowed;
		}
	}
	input {
		display: none;
	}
	.tooltip {
		margin-top: -30px;
		&.right {
			margin-top: 0;
		}
	}
	.tooltip-inner {
		white-space: nowrap;
	}
}
.slider-track {
	position: absolute;
	cursor: pointer;
	background: @slider-track-background;
	border-radius: @slider-border-radius;
}
.slider-selection {
	position: absolute;
	background: @slider-selection-background;
	.box-sizing(border-box);
	border-radius: @slider-border-radius;
}
.slider-handle {
	position: absolute;
	width: @slider-line-height;
	height: @slider-line-height;
	background-color: @slider-handle-background;
	filter: none;
	opacity: 1;
	border: 0px solid transparent;
	&.round {
		border-radius: 50%;
		background-color: @slider-track-background;
		border: 6px solid @slider-handle-background;
		&:before {
			content:"";
			position: absolute;
			top: -7px;
			left:-7px;
			width: @slider-line-height+2;
			height: @slider-line-height+2;
			border:1px solid @white;
			border-radius: 50%;
		}
	}
	&.triangle {
		background: transparent none;
	}
}
