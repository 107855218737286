/* -----------------------------------------
   Tags input
----------------------------------------- */
div.tagsinput {
    border:1px solid @input-border; 
    border-radius: @input-border-radius;
    background: @white; 
    padding:5px; 
    width: auto; 
    height: @input-height-base; 
    overflow-y: auto;

    span.tag {
        border: 1px solid darken(@teal, 5%); 
        border-radius: @border-radius-base;
        display: block; 
        float: left; 
        padding: 5px; 
        text-decoration:none; 
        background: @teal; 
        color: @white; 
        margin-right: 5px; 
        margin-bottom:5px;
        font-size:13px;

        span {padding-left: 5px;}

        a {
            font-weight: bold; 
            color: darken(@teal, 25%); 
            text-decoration:none; 
            font-size: 12px;
            float: right;

            &:hover {
                color: darken(@teal, 45%); 
            }
        }
    }
    input {
        width:auto; 
        margin:0px; 
        font-size: 13px; 
        border:1px solid transparent; 
        padding:5px; 
        background: transparent; 
        outline:0px;  
        margin-right:5px; 
        margin-bottom:5px; 
    }
    div {
        display:block; 
        float: left;
    }
}
.tags_clear { 
    clear: both; 
    width: 100%; 
    height: 0px; 
}
.not_valid {
    background: @red !important; 
    color: @white !important;
    border-right: @border-radius-base;
}