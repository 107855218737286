/* -----------------------------------------
   Plupload
----------------------------------------- */
.plupload_wrapper * {
	box-sizing: content-box;
}

.plupload_button {
	display: -moz-inline-box; /* FF < 3*/
	display: inline-block;
	font: normal 12px sans-serif;
	text-decoration: none;
    color: @text-color;
    border: 1px solid @border-color;
    padding: 2px 8px 3px 20px;
	margin-right: 4px;
    background: @gray url('../plugins/file/plupload/img/buttons.png') no-repeat 0 center;
	outline: 0;

   border-radius: @border-radius-base;
}

.plupload_button:hover {
	color: #000;
	text-decoration: none;
}

.plupload_disabled, a.plupload_disabled:hover {
    color: lighten(@text-color, 10%);
    border-color: lighten(@border-color, 10%);
    background: #ededed url('../plugins/file/plupload/img/buttons-disabled.png') no-repeat 0 center;
	cursor: default;
}

.plupload_add {
	background-position: -181px center;
}

.plupload_wrapper {
	font: normal 11px Verdana,sans-serif;
	width: 100%;
}

.plupload_container {
	padding: 8px;
	/*-moz-border-radius: 5px;*/
}

.plupload_container input {
	border: 1px solid @border-color;
	font: normal 11px 'Open Sans', Verdana,sans-serif;
	width: 98%;
}

.plupload_header {background: @mainColor;}
.plupload_header_content {
	min-height: 56px;
	padding-left: 10px;
	color: #FFF;
}
.plupload_header_title {
	font: normal 18px 'Open Sans', sans-serif;
	padding: 6px 0 3px;
}
.plupload_header_text {
	font: normal 12px 'Open Sans', sans-serif;
}

.plupload_filelist {
	margin: 0;
	padding: 0;
	list-style: none;
}

.plupload_scroll .plupload_filelist {
	height: 185px;
	background: #F5F5F5;
	overflow-y: scroll;
}

.plupload_filelist li {
	padding: 10px 8px;
	background: #F5F5F5 url('../plugins/file/plupload/img/backgrounds.gif') repeat-x 0 -156px;
	border-bottom: 1px solid @border-color;
}

.plupload_filelist_header, .plupload_filelist_footer {
	background: #DFDFDF;
	padding: 8px 8px;
	color: #42454A;
}
.plupload_filelist_header {	
	border-top: 1px solid #EEE;
	border-bottom: 1px solid #CDCDCD;
}

.plupload_filelist_footer {border-top: 1px solid #FFF; height: 22px; line-height: 20px; vertical-align: middle;}
.plupload_file_name {float: left; overflow: hidden}
.plupload_file_status {color: #777;}
.plupload_file_status span {color: #42454A;}
.plupload_file_size, .plupload_file_status, .plupload_progress {
	float: right;
	width: 80px;
}
.plupload_file_size, .plupload_file_status, .plupload_file_action {text-align: right;}

.plupload_filelist .plupload_file_name {
	width: 205px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.plupload_file_action {
	float: right;
	width: 16px;
	height: 16px;
	margin-left: 15px;
}

.plupload_file_action * {
	display: none;
	width: 16px;
	height: 16px;
}

li.plupload_uploading {background: #ECF3DC url('../plugins/file/plupload/img/backgrounds.gif') repeat-x 0 -238px;}
li.plupload_done {color:#AAA}

li.plupload_delete a {
	background: url('../plugins/file/plupload/img/delete.gif');
}

li.plupload_failed a {
	background: url('../plugins/file/plupload/img/error.gif');
	cursor: default;
}

li.plupload_done a {
	background: url('../plugins/file/plupload/img/done.gif');
	cursor: default;
}

.plupload_progress, .plupload_upload_status {
	display: none;
}

.plupload_progress_container {
	margin-top: 3px;
	border: 1px solid #CCC;
	background: #FFF;
	padding: 1px;
}
.plupload_progress_bar {
	width: 0px;
	height: 7px;
	background: #CDEB8B;
}

.plupload_scroll .plupload_filelist_header .plupload_file_action, .plupload_scroll .plupload_filelist_footer .plupload_file_action {
	margin-right: 17px;
}

/* Floats */

.plupload_clear,.plupload_clearer {clear: both;}
.plupload_clearer, .plupload_progress_bar {
	display: block;
	font-size: 0;
	line-height: 0;	
}

li.plupload_droptext {
	background: transparent;
	text-align: center;
	vertical-align: middle;
	border: 0;
	line-height: 165px;
}