/* -----------------------------------------
   Progressbars
----------------------------------------- */

.progress {
  position: relative;
}

.progress .progress-bar {
  position: absolute;
  overflow: hidden;
  line-height: @line-height-computed;
  .transition(width 2s ease-in-out);
}

.progress .progressbar-back-text {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: @progressbarFontSize;
  line-height: @line-height-computed;
  text-align: center;
}

.progress .progressbar-front-text {
  display: block;
  width: 100%;
  font-size: @progressbarFontSize;
  line-height: @line-height-computed;
  text-align: center;
}

// bootstrap-progressbar horizontal styles
// ---------------------------------------

.progress.right .progress-bar {
  right: 0;
}

.progress.right .progressbar-front-text {
  position: absolute;
  right: 0;
}

// bootstrap-progressbar vertical styles
// -------------------------------------

.progress.vertical {
  width: @progressbarVerticalWidth;
  height: 100%;
  float: left;
  margin-right: @progressbarVerticalWidth;
}

.progress.vertical.bottom {
  position: relative;
}

.progress.vertical.bottom .progressbar-front-text {
  position: absolute;
  bottom: 0;
}

.progress.vertical .progress-bar {
  width: 100%;
  height: 0;
  .transition(height .6s ease);
}

.progress.vertical.bottom .progress-bar {
  position: absolute;
  bottom: 0;
}