@media screen and (min-width: @screen-sm-max) {
  .col-sm-border:not(:last-child) {
    border-right: 1px solid #d7d7d7;
  }
  .col-sm-border + .col-sm-border {
    border-left: 1px solid #d7d7d7;
    margin-left: -1px;
  }
}

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

#header.local .navbar {
  background: @green;
}

#header.dev .navbar {
  background: @green;
}

#header.test .navbar {
  background: @green;
}

#header.staging .navbar {
  background: @green;
}

#header.qa .navbar {
  background: @green;
}

.login-page.local {
  background: @green;
}

.login-page.dev {
  background: @green;
}

.login-page.test {
  background: @green;
}

.login-page.staging {
  background: @green;
}

.login-page.qa {
  background: @green;
}

.help-block {
  color: lighten(@text-color, 10%); // lighten the text some for contrast
}

.btn.btn-default {
  font-weight: 300;
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url("@{donationmatch_cdn}/images/theme/ui-icons_454545_256x240.png");
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url("@{donationmatch_cdn}/images/theme/ui-icons_454545_256x240.png");
}

.ui-widget-header .ui-icon {
  background-image: url("@{donationmatch_cdn}/images/theme/ui-icons_636363_256x240.png");
}

.ui-icon,
.ui-panel-body .ui-icon {
  background-image: url("@{donationmatch_cdn}/images/theme/ui-icons_636363_256x240.png");
}

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("@{donationmatch_cdn}/images/theme/ui-icons_222222_256x240.png");
}

.ui-state-highlight .ui-icon {
  background-image: url("@{donationmatch_cdn}/images/theme/ui-icons_62aeef_256x240.png");
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #dadada
    url("@{donationmatch_cdn}/images/theme/ui-bg_glass_75_dadada_1x400.png") 50%
    50% repeat-x;
}

.watermark {
  color: #d0d0d0;
  font-size: 50pt;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: -1;
  left: -300px;
  top: 400px;
}

.tag {
  border: 1px solid #e4e9eb;
  background: #e4e9eb;
  border-radius: 4px;
  padding: 1px;
  width: auto;
  overflow-y: auto;
  margin: 4px;
}

th {
  text-align: center;
}

.table-search {
  float: left;
}

.row-count-header {
  float: right;
}

.row-count-footer {
  float: left;
  margin-left: 10px;
}

.row-count-pre {
  float: left;
  margin: 5px 5px 5px 0;
}

.row-count {
  float: left;
}

.row-count-post {
  float: left;
  margin: 5px 0 0px 5px;
}

.row-header {
  margin-right: 10px;
}
.row-info-header {
  padding-right: 0;
  text-align: right;
  float: none !important;
}

.row-count .dataTables_length {
  text-align: right;
}

.table-search .dataTables_wrapper,
.table-search .dataTables_filter {
  text-align: left !important;
}

#filters-event-create .row-count {
  max-width: 100px;
}

div#nonprofit-matches-datatables_filter.dataTables_filter {
  margin-right: 10px;
}

#footer.footer {
  height: 24px; // Replace with the height your footer should be
  width: 100%; // Don't change
  background-image: none;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-color: @blue;
  color: @white;
  position: fixed;
  bottom: 0pt;
  left: 0pt;
  z-index: 100;
}

.footer_contents {
  height: 24px; // Replace with the height your footer should be
  width: 100%; // Visible width of footer
  margin: auto;
  padding-left: 5px;
  text-align: center;
}

@media (max-width: @screen-sm-max) {
  #createProfile .modal-content {
    min-width: initial !important;
  }
}

.tile.white {
  background-color: #ffffff;
  color: black;
  border-color: black;
  border-style: solid;
  border-width: 2px;
}

.tile.white:hover {
  background-color: black;
  color: white;
}
.vertical-align {
  display: flex;
  align-items: center;
  /* add class, set height in parent div*/
}

div.tagsinput span.tag {
  border: 1px solid @blue;
  background: @blue;
}

.wizard-steps.show .wstep {
  cursor: pointer;
}

.wstep.current {
  color: @blue !important;
}

button[disabled].btn.pull-left.ui-wizard-content.ui-formwizard-button {
  display: none;
}
ul.pie-legend {
  position: absolute;
}

div#match-company-index .nav-pills > li {
  margin-right: 10px;
}

div#match-nonprofit-index .nav-pills > li {
  margin-right: 10px;
}

.item-create-form .submit-wizard input.btn {
  color: #ffffff;
  background-color: #3ac7ee;
  border-color: #3ac7ee;
}

.col-md-3.fixed-top-right {
  position: fixed;
  top: 135px;
  right: 100px;
}

div#company-matches-datatables_filter.dataTables_filter {
  padding-right: 20px;
}

.form-group.required .control-label:after {
  content: "*";
  color: @red;
  font-family: "Glyphicons Halflings";
  font-weight: normal;
  font-size: 70%;
}

h4.required:after,
h5.required:after {
  content: "*";
  color: @red;
  font-family: "Glyphicons Halflings";
  font-weight: normal;
  font-size: 70%;
}

.has-error .checkbox {
  color: @red;
}

.has-error .radio {
  color: @red;
}

.has-error label.help-block {
  color: @red;
}

.has-error label.control-label {
  color: @red;
}

.has-success .control-label {
  color: @text-color;
}

.has-error .mce-tinymce {
  border-color: @red;
}

.has-error h5 {
  color: @red;
}

.wide-error.has-error p {
  min-width: 400px;
}

.form-horizontal .form-group.no-error .form-control,
.form-inline .form-group.has-error .form-control {
  border-color: @border-color !important;
}

li.btn-warning a:hover,
li.btn-warning a:focus {
  background-color: #f19932;
  border-color: #f09529;
  color: #333333;
}

li.btn-danger a:hover,
li.btn-danger a:focus {
  background-color: #f35454;
  border-color: #f24b4b;
  color: #333333;
}

.yesno .onoffswitch-inner:before {
  content: "Yes";
}

.yesno .onoffswitch-inner:after {
  content: "No";
}

input[placeholder] {
  width: 100% !important;
}

/*Certificate CSS*/

.certificate {
  background-color: #fff;
  font-family: Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
  margin: 8px;
}

.certificate body {
  margin: 1em;
  font-weight: normal;
  font-weight: 400;
  font-size: 13px;
}

.certificate h3 {
  font-size: 15px;
}

.certificate tbody {
  border-collapse: collapse;
  border-top-color: rgb(204, 204, 204);
  border-top-style: solid;
  border-top-width: 1px;
}

th .certificate {
  text-align: left; /* LTR */
  color: #006;
  border-bottom: 1px solid #ccc;
}
tr.odd .certificate {
  background-color: #ddd;
}
tr.even .certificate {
  background-color: #fff;
}
.certificate td {
  padding: 5px;
  font-weight: 400;
  font-size: 14px;
}

.certificate a:link {
  color: #000;
}

.certificate a:visited {
  color: #000;
}

.certificate a:hover {
  color: #00f;
}

.certificate a:link img,
.certificate a:visited img {
  border: 0;
}

.certificate img.logo {
  border: 0;
  padding: 5px;
  max-width: 200px;
  max-height: 200px;
}

.certificate-title {
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 3px;
}

h1.certificate-title {
  margin: 5px;
  font-size: 20px;
  font-weight: 700;
}

.certificate .content {
  font-size: 10px;
}

.certificate .hr {
  border: 0px;
}

.certificate-message {
  border: medium dotted blue;
  padding: 1em;
}
.certificate-source_url {
  font-size: small;
}

.certificate-links {
  font-size: small;
}

.certificate .footer {
  font-size: 9px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  margin-bottom: 10px;
}

.certificate .instructions {
  font-size: 9px;
  color: #00a6d8 !important;
  margin-top: 10px;
}

.certificate p {
  margin-bottom: 2px;
}

.certificate .instructions p {
  margin-bottom: 2px;
}

/* Nonprofit Edit form */
@media (max-width: 350px) {
  input#approve.btn.pull-right.btn-warning {
    float: none !important;
    margin-top: -14px;
  }
}

#filters-event-create .btn-group {
  float: left;
}

#filters-event-create i.fa-level-down {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.reverse {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

@media all and (max-width: 640px) {
  /* ------------------ Header --------------------*/
  #header .navbar .top-nav {
    height: 50px;
    text-align: center;
    float: none;
  }

  #header .navbar .top-nav .navbar-nav {
    margin: 0;
    min-width: 200px;
    float: left !important;
    height: initial;
  }

  #header .navbar .nav li.dropdown .dropdown-menu {
    left: 200px;
  }

  /* ------------------ Sidebars --------------------*/
  #sidebar,
  #right-sidebar {
    top: 50px;
  }

  /* ------------------ Content --------------------*/
  #content {
    padding-top: 50px;
  }
}

body,
.checkbox label {
  font-weight: 400;
}

#content .content-wrapper {
  padding-top: 20px;
}

.dropdown-menu > li > a.btn-danger:hover,
.dropdown-menu > li > a.btn-danger:focus {
  color: #ffffff;
  background-color: #f35454;
}

.dropdown-menu > li > a.btn-danger {
  color: #ffffff;
  font-weight: 600;
}

.dropdown-menu > li > a.btn-warning:hover,
.dropdown-menu > li > a.btn-warning:focus {
  color: #ffffff;
  background-color: #f19932;
}

.dropdown-menu > li > a.btn-warning {
  color: #ffffff;
  font-weight: 600;
}

.dropdown-menu > li > a.btn-success:hover,
.dropdown-menu > li > a.btn-success:focus {
  color: #ffffff;
  background-color: #4ac77c;
}

.dropdown-menu > li > a.btn-success {
  color: #ffffff;
  font-weight: 600;
}

.dropdown-menu > li > a.btn-yellow:hover,
.dropdown-menu > li > a.btn-yellow:focus {
  color: #ffffff;
  background-color: #ffbb33;
}

.dropdown-menu > li > a.btn-yellow {
  color: #ffffff;
  font-weight: 600;
}

.dropdown-menu > li > a.btn-info:hover,
.dropdown-menu > li > a.btn-info:focus {
  color: #ffffff;
  background-color: #73c4b1;
}

.dropdown-menu > li > a.btn-info {
  color: #ffffff;
  font-weight: 600;
}

#header .navbar .nav li a {
  font-size: 16px;
}

#right-sidebar {
  bottom: initial;
  height: initial;
}

#right-sidebar .sidebar-inner {
  padding-bottom: 10px;
}

ul.chat-ui.bsAccordion {
  margin-bottom: 8px;
}

ul.dropdown-menu.email {
  margin-left: -300px;
}

.panel-heading {
  font-weight: bold;
  font-size: 18px;
}

.show-event-logo {
  margin-bottom: 15px;
  max-height: 200px;
  max-width: 200px;
}

.show-small-organization-logo {
  max-height: 20px;
  max-width: 40px;
  margin-right: 5px;
  margin-bottom: 5px;
}

@media (max-width: @screen-md-min) {
  #filters-event-create #filters {
    position: initial;
    top: auto;
    right: initial;
  }
}

.icheckbox_flat-green,
.iradio_flat-green {
  background-image: url("@{icheck-path}/images/icheck/green.png");
}

.icheckbox_flat,
.iradio_flat {
  background-image: url("@{icheck-path}/images/icheck/flat.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-green,
  .iradio_flat-green {
    background-image: url("@{icheck-path}/images/icheck/green@2x.png");
  }
}

.icheckbox_flat-blue,
.iradio_flat-blue {
  background-image: url("@{icheck-path}/images/icheck/blue.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat,
  .iradio_flat {
    background-image: url("@{icheck-path}/images/icheck/flat@2x.png");
  }
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-blue,
  .iradio_flat-blue {
    background-image: url("@{icheck-path}/images/icheck/blue@2x.png");
  }
}

.icheckbox_flat-areo,
.iradio_flat-areo {
  background-image: url("@{icheck-path}/images/icheck/areo.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-areo,
  .iradio_flat-areo {
    background-image: url("@{icheck-path}/images/icheck/areo@2x.png");
  }
}

.icheckbox_flat-grey,
.iradio_flat-grey {
  background-image: url("@{icheck-path}/images/icheck/grey.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-grey,
  .iradio_flat-grey {
    background-image: url("@{icheck-path}/images/icheck/grey@2x.png");
  }
}

.icheckbox_flat-orange,
.iradio_flat-orange {
  background-image: url("@{icheck-path}/images/icheck/orange.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-orange,
  .iradio_flat-orange {
    background-image: url("@{icheck-path}/images/icheck/orange@2x.png");
  }
}

.icheckbox_flat-yellow,
.iradio_flat-yellow {
  background-image: url("@{icheck-path}/images/icheck/yellow.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-yellow,
  .iradio_flat-yellow {
    background-image: url("@{icheck-path}/images/icheck/yellow@2x.png");
  }
}

.icheckbox_flat-pink,
.iradio_flat-pink {
  background-image: url("@{icheck-path}/images/icheck/pink.png");
}

.daterangepicker .input-mini {
  width: 100% !important;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-pink,
  .iradio_flat-pink {
    background-image: url("@{icheck-path}/images/icheck/pink@2x.png");
  }
}

.icheckbox_flat-purple,
.iradio_flat-purple {
  background-image: url("@{icheck-path}/images/icheck/purple.png");
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_flat-purple,
  .iradio_flat-purple {
    background-image: url("@{icheck-path}/images/icheck/purple@2x.png");
  }
}

.dropzone .dz-preview .dz-error-message {
  /*  top: 80px;*/
}

.rating-input {
  font-size: 18px;
  color: @yellow;
}

body.login-page #login.dm img#logo {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  position: static;
}

body.login-page #login.sfg img#logo {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  position: static;
}

body.login-page #login.winspire img#logo {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0;
  position: static;
}

.dm #logo {
  width: 210px;
  background-color: white;
  border-radius: 10px;
  margin: 6px;
}

.sfg #logo {
  width: 210px;
  background-color: white;
  border-radius: 10px;
  margin: 6px;
}

.winspire #logo {
  width: 240px;
  margin: 6px;
}

.winspire ul.nav.navbar-nav {
  padding-left: 30px;
}

.mask-zipCode {
  text-transform: uppercase;
}

input[type="radio"].noStyle,
input[type="checkbox"].noStyle {
  cursor: pointer;
}

html {
  font-size: 14px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.panel-body.dashboard {
  height: 320px;
}

.legacy .row,
.column,
.flex {
  display: block;
  flex-wrap: no-wrap;
}

.legacy .row {
  margin-left: -15px;
  margin-right: -15px;
}

.legacy h1,
.legacy h2,
.legacy h3,
.legacy h4,
.legacy h5,
.legacy h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 0;
}

.legacy h1 {
  font-size: 36px;
}

.legacy h2 {
  font-size: 30px;
}

.legacy h3 {
  font-size: 24px;
}

.legacy h4 {
  font-size: 18px;
}

.legacy h5 {
  font-size: 14px;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: @red;
}

label.checkbox {
  width: max-content;
  cursor: pointer;
}

.panel-title > a {
  font-weight: bold;
}

p.ein-verification {
  background-size: 1px;
  border-style: solid;
  border-color: #f68484;
  border-radius: 2px;
  padding: 5px;
  margin-top: 10px;
}
