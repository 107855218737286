/* -----------------------------------------
   Highlight.js github style
----------------------------------------- */
.hljs {
  display: block; padding: 0.5em;
  color: #333;
  background: #f8f8f8
}

.hljs-comment,
.hljs-template_comment,
.diff .hljs-header,
.hljs-javadoc {
  color: #998;
  font-style: italic
}

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.javascript .hljs-title,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #333;
  font-weight: bold
}

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #099;
}

.hljs-string,
.hljs-tag .hljs-value,
.hljs-phpdoc,
.tex .hljs-formula {
  color: #d14
}

.hljs-title,
.hljs-id,
.coffeescript .hljs-params,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold
}

.javascript .hljs-title,
.lisp .hljs-title,
.clojure .hljs-title,
.hljs-subst {
  font-weight: normal
}

.hljs-class .hljs-title,
.haskell .hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold
}

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rules .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #000080;
  font-weight: normal
}

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body {
  color: #008080
}

.hljs-regexp {
  color: #009926
}

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073
}

.hljs-built_in,
.lisp .hljs-title,
.clojure .hljs-built_in {
  color: #0086b3
}

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold
}

.hljs-deletion {
  background: #fdd
}

.hljs-addition {
  background: #dfd
}

.diff .hljs-change {
  background: #0086b3
}

.hljs-chunk {
  color: #aaa
}